import React from "react"
import Layout from "../components/layout"

import {StaticImage} from "gatsby-plugin-image"

import "../styles/ecosystem-benefits.css"

const EcosystemBenefits = () => {
    return (
        <Layout>
            <div className="text-center benefitHero flex justify-center items-center">
                <div className="container mx-auto pt-24 pb-8 px-10 text-center">
                    <h1 className="text-white text-3xl md:text-4xl lg:text-6xl font-extralight text-shadow">
                        The Digital Enterpreneurship Ecosystem Value
                    </h1>
                </div>
            </div>
            {/* Completed responsiveness */}

            <div className="flex justify-center mt-16">
                <div className="md:w-1/2 mx-8">
                    <h2 className="text-center text-2xl md:text-4xl text-blue-500 font-light">
                        Here are <b className="text-4xl md:text-5xl">5 reasons</b> to join
                        your business or venture in our database:
                    </h2>
                </div>
            </div>
            {/* Completed responsiveness */}

            <div className="flex justify-center pt-6 pb-10">
                <div className="grid mt-6 md:w-3/4">
                    <div className="grid md:grid-cols-2 justify-center items-center">
                        <div className="mx-6 ">
                            {/* <h1 className="text-3xl orangeText">Reason 1</h1> */}
                            <StaticImage
                                src="../images/benefits/finance.png"
                                alt="Finance"
                                className="w-full shadow-lg rounded-md mt-6"
                            />
                        </div>
                        <div className="mx-6 mt-8">
                            <div className="flex justify-end mb-5">
                                <p className="bg-blue-500 rounded-full py-2 px-5 text-white">
                                    1
                                </p>
                            </div>
                            <p className="text-right">
                                Enhance coordination, rapport and specialisation within the
                                entrepreneurship domain for accurate assessments of impact.
                            </p>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 justify-center items-center mt-8 md:mt-0">
                        <div className="mx-6 ">
                            <div className="flex justify-start mb-5">
                                <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                    2
                                </p>
                            </div>
                            <p>
                                Comprehend the gaps in private sector logistics as they exist
                                and render much-needed relief to ease prevailing constraints.
                            </p>
                        </div>
                        <div className="mx-6 mt-8">
                            {/* <h1 className="text-3xl orangeText text-right">Reason 2</h1> */}
                            <StaticImage
                                src="../images/benefits/logistics.png"
                                alt="Logistics"
                                className="w-full shadow-lg rounded-md mt-6"
                            />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 justify-center items-center">
                        <div className="mx-6 ">
                            {/* <h1 className="text-3xl orangeText">Reason 3</h1> */}
                            <StaticImage
                                src="../images/benefits/startup.png"
                                alt="Startups"
                                className="w-full shadow-lg rounded-md mt-6"
                            />
                        </div>
                        <div className="mx-6 mt-8">
                            <div className="flex justify-end mb-5">
                                <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                    3
                                </p>
                            </div>
                            <p className="text-right">
                                Devise feasible plans designed to help startups and emerging
                                entrepreneurs grow.
                            </p>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 justify-center items-center mt-8 md:mt-0">
                        <div className="mx-6 ">
                            <div className="flex justify-start mb-5">
                                <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                    4
                                </p>
                            </div>
                            <p>
                                Develop and execute projects aiming to cultivate public interest
                                and accelerate innovation for multi-sectoral development.
                            </p>
                        </div>
                        <div className="mx-6 mt-8">
                            {/* <h1 className="text-3xl orangeText text-right">Reason 4</h1> */}
                            <StaticImage
                                src="../images/benefits/ideation.png"
                                alt="Ideation"
                                className="w-full shadow-lg rounded-md mt-6"
                            />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 justify-center items-center">
                        <div className="mx-6 ">
                            {/* <h1 className="text-3xl orangeText">Reason 5</h1> */}
                            <StaticImage
                                src="../images/benefits/transformation.png"
                                alt="Transform"
                                className="w-full shadow-lg rounded-md mt-6"
                            />
                        </div>
                        <div className="mx-6 mt-8">
                            <div className="flex justify-end mb-5">
                                <p className="text-center bg-blue-500 rounded-full py-2 px-4 text-white">
                                    5
                                </p>
                            </div>
                            <p className="text-right">
                                Carve and transform Sierra Leone into a rich, diverse and
                                flourishing entrepreneurship centre
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Completed responsiveness */}

            <div className="container mx-auto bg-gray-200 py-16 rounded-t-md">
                <div className="flex justify-center">
                    <div className="mx-8 md:w-4/5 grid md:flex">
                        <div className="md:w-1/3 md:mr-5 mb-5">
                            <h3 className="font-bold text-2xl md:text-4xl text-blue-500">
                <span className="font-thin text-2xl md:text-4xl">
                  Other things the
                </span>{" "}
                                Ecosystem{" "}
                                <span className="font-thin text-2xl md:text-4xl">offers</span>
                            </h3>
                        </div>

                        <div className="md:w-2/3">
                            <ul>
                                <li className="mt-2 flex">
                                    <p>
                                        <b className="text-blue-500">
                                            Strengthening the Sierra Leone entrepreneurship ecosystem
                                            -{" "}
                                        </b>
                                        through this platform, we desire to unite and build a
                                        community of problem-solvers through different events and
                                        activities, creating and executing unified marketing and
                                        branding strategies, assisting scale up development and
                                        data-based decision making.
                                    </p>
                                </li>
                                <li className="mt-3 flex">
                                    <p>
                                        <b className="text-blue-500">
                                            Be part of organizing entrepreneurship events –{" "}
                                        </b>
                                        The aim here is through community Mobilisation, advocating
                                        diversity in age, gender, sector and locations.
                                    </p>
                                </li>
                                <li className="mt-3 flex ">
                                    <p>
                                        <b className="text-blue-500">
                                            Partnership opportunities –{" "}
                                        </b>
                                        working with both local and international partners to up
                                        skill the entrepreneurship community in Sierra Leone. Also,
                                        through partnerships, connecting entrepreneurs to available
                                        resources and vital information and know-how.
                                    </p>
                                </li>
                                <li className="mt-3 flex">
                                    <p>
                                        <b className="text-blue-500">
                                            Working on policy and regulatory issues and barriers –
                                        </b>
                                        advocating for the change of bad policies and regulations
                                        that are complicating the process of operating business,
                                        investment or access to funding in Sierra Leone, and
                                        implementing entrepreneurship friendly regulations.
                                    </p>
                                </li>
                                <li className="mt-3 flex">
                                    <p>
                                        <b className="text-blue-500">
                                            Marketing help on our platforms and network –
                                        </b>
                                        We issue newsletter, share news on our social channels
                                        (Facebook, Instagram and Twitter) and blog and gather all
                                        events in a calendar.
                                    </p>
                                </li>
                                <li className="mt-3 flex">
                                    <p>
                                        <b className="text-blue-500">
                                            Grants and funding information –
                                        </b>
                                        We hope to provide funding and grants opportunities updates
                                        to develop new services, events, and smaller projects that
                                        support startups in their growth.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Completed responsiveness */}
        </Layout>
    )
}

export default EcosystemBenefits
